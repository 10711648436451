<template>
  <div id="project-list">
    <!-- user total card -->
    <v-row class="mb-5"
      v-if="$can('read', 'project')">
      <v-col
        v-for="total in projectTotalLocal"
        :key="total.id"
        cols="12"
        sm="4"
        md="2"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total_projects }}
              </h2>
              <span>{{ total.name }}</span>
            </div>

            <v-avatar
              :color="resolveProjectTotalIcon(total.name).color"
              :class="`v-avatar-light-bg ${resolveProjectTotalIcon(total.name).color}--text`"
            >
              <v-icon
                size="25"
                :color="resolveProjectTotalIcon(total.name).color"
                class="rounded-0"
              >
                {{ resolveProjectTotalIcon(total.name).icon }}
              </v-icon>
            </v-avatar>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card >
      <v-card-title>
        Projects List
        <v-spacer></v-spacer>
        <v-switch
          v-model="view"
          :label="`${view.toString()}`"
          true-value="Project"
          false-value="Accounting"
          hide-details
          dense
          class="mt-0"
          v-if="$can('update','accounting')"
        ></v-switch>
        <v-menu
            bottom
            left
          v-if="view=='Accounting'"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :style="`cursor: pointer`"
                @click="clickDownloadProjects"
              >
                <v-list-item-title
                >
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDownload }}
                  </v-icon>
                  <span>Download</span>
                </v-list-item-title>
              </v-list-item>

            </v-list>
          </v-menu>
      </v-card-title>
      <div 
      v-if="$can('read', 'project')">
        <v-row class="px-2 ma-0">
          <!-- dept filter -->
          <v-col
            cols="12"
            sm="4"
          >
            <v-autocomplete
              v-model="deptFilter"
              placeholder="Select Department"
              label="Department"
              :loading="deptLoading"
              :items="deptOptions"
              :search-input.sync="deptSearch"
              item-text="label"
              item-value="value"
              cache-items
              outlined
              clearable
              dense
              hide-details
              small-chips
              deletable-chips
              multiple
              @change="deptSearch=''"
              @input="updateFilter('dept', $event)"
            ></v-autocomplete>
          </v-col>

          <!-- group filter -->
          <v-col
            cols="12"
            sm="4"
          >
            <v-autocomplete
              v-model="groupFilter"
              placeholder="Select Group"
              label="Group"
              :loading="groupLoading"
              :items="groupOptions"
              :search-input.sync="groupSearch"
              item-text="name"
              item-value="id"
              cache-items
              outlined
              clearable
              dense
              hide-details
              multiple
              small-chips
              deletable-chips
              @change="groupSearch=''"
              @input=" updateFilter('group', $event)"
            ></v-autocomplete>
          </v-col>

          <!-- office filter -->
          <v-col
            cols="12"
            sm="4"
          >
            <v-autocomplete
              v-model="officeFilter"
              placeholder="Select Office"
              label="Office"
              :loading="officeLoading"
              :items="officeOptions"
              :search-input.sync="officeSearch"
              item-text="label"
              item-value="value"
              cache-items
              outlined
              clearable
              dense
              hide-details
              multiple
              small-chips
              deletable-chips
              @change="officeSearch=''"
              @input=" updateFilter('office', $event)"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <!-- <v-row class="px-2 ma-0">
          <v-col
            cols="12"
            sm="4"
          >
            <v-autocomplete
                v-model="statusFilter"
                placeholder="Select Statuses"
                label="Statuses"
                :loading="statusLoading"
                :items="statusOptions"
                :search-input.sync="statusSearch"
                item-text="label"
                item-value="value"
                cache-items
                outlined
                dense
                hide-details
                multiple
                small-chips
                deletable-chips
                @change="statusSearch=''"
                @input=" updateFilter('status', $event)"
              >
                <template #selection="{ item }">
                  <v-chip :color="`${resolveCountryStatusVariant(item.value)}`" small>{{item.value}}</v-chip>
                </template>
              </v-autocomplete>
          </v-col>
        </v-row> -->

        <v-divider class="mt-4"></v-divider>
        </div>
        
        <div 
        v-if="$can('update', 'project')"
        class="mt-4">
        <!-- actions -->
        <v-card-text class="d-flex align-center flex-wrap pb-0">
          <v-row>
            <!-- search -->
            <v-col
              cols="12"
              sm="4"
            >
            <v-form
                @submit="submitSearch($event)"
            >
                <v-text-field
                v-model="searchQuery"
                placeholder="Search"
                outlined
                hide-details
                dense
                clearable
                class="mb-4"
                :append-icon="icons.mdiSend"
                @click:append="submitSearch"
                @click:append-outer="submitSearch"
                @click:clear="submitSearch"
                >
                </v-text-field>
            </v-form>
          </v-col>
          </v-row>
        </v-card-text>
      </div>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="projectListTable"
        :server-items-length="totalProjectListTable"
        :expanded.sync="expanded"
        :show-expand="view=='Accounting'"
        :loading="loading"
        :options.sync="options"
        :footer-props="{
            'items-per-page-options':[options.itemsPerPage],
            'disable-items-per-page': true,
        }"
      >
        <!-- job name -->
        <template #[`item.job`]="{item}">
          <div class="d-flex align-left">
            <div class="d-flex flex-column ms-3">
              <router-link
                :is="view=='Accounting' ? 'span' : 'router-link'"
                :to="{ name : 'project-view', params : { id: item.job_number } }"
                target="_blank"
                class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none"

              >
                {{ item.job }}
              </router-link>
              <small>@{{ item.office }}</small>
            </div>
          </div>
        </template>

        <!-- status -->
        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="resolveProjectStatusVariant(item.status)"
            :class="`${resolveProjectStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ item.status }}
          </v-chip>
        </template>

        <!-- Service Type -->
        <template #[`item.research_method`]="{item}">
          <div class="d-flex flex-column ms-3">
            <span class="text-capitalize text--primary">{{ item.research_method }}</span>
            <span>{{ item.project_type }}</span>
          </div>
        </template>

        <!-- Service Type -->
        <template #[`item.service_type`]="{item}">
          <div class="d-flex flex-column ms-3">
            <span class="text-capitalize font-weight-semibold text--primary">{{ item.service_type }}</span>
          </div>
        </template>

        <!-- countries -->
        <template #[`header.countries`]="{}" >
            <v-row>
              <v-col
                cols="12"
                md="4">
                  COUNTRIES
                </v-col>
                <v-col
                cols="12"
                md="2">
                  CELLS
                </v-col>
                <v-col
                cols="12"
                md="3">
                  SAMPLE
                </v-col>
                <v-col
                cols="12"
                md="3">
                  FIELD START
                </v-col>
            </v-row>
        </template>
        
        <template #[`item.countries`]="{item}">
         
            <v-row v-for="country in item.countries" :key="country.id" >
                <v-col
                cols="12"
                md="4">
                    {{country.country_detail.name}}  
                </v-col>
                
                <v-col
                cols="12"
                md="2">
                {{country.phase}}  
                </v-col>
                <v-col
                cols="12"
                md="3">
                    {{country.sample}}  
                </v-col>
                <v-col
                cols="12"
                md="3">
                    {{country.field_start_date}}
                </v-col>
            </v-row>
        </template>

        <!-- contact -->
        <template #[`item.contact`]="{item}">
        <section class="avatars-group pa-3 stacked">
          <div class="avatars-group__item"
          >
            <v-tooltip 
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on">
                  <v-avatar
                    :color="stringToHslColor(item.contact.name) || primary"
                    size="30"
                  >
                    <span class="white--text text-xs">{{avatarText(item.contact.name)}}</span>
                  </v-avatar>
                </div>
              </template>
              <v-layout column>
                <span class="font-weight-medium">{{item.contact.name}}</span>
              </v-layout>
            </v-tooltip>
          </div>
        </section>
        </template>

        <template #[`item.budget`]="{item}">
           <div class="d-flex flex-column">
            <div class="d-flex align-center">
              <span class="primary--text font-weight-medium">{{ item.budget.toLocaleString('fr-FR') }}</span>
              <sup class="primary--text text-muted">{{item.currency.name}}</sup>
            </div>
            <span class="text-xs text-no-wrap">Rule: {{ item.billing_rule.name }}</span>
          </div>
        </template>

        <template #[`item.first_invoice_billed`]="{item}">
          <span class=" font-weight-medium">{{ item.first_invoice_amount.toLocaleString('fr-FR') }}</span>
          <v-edit-dialog
            :return-value.sync="item.first_invoice_billed"
            large
            @save="updateBilling(item, 'first_invoice_billed')"
          >
            <v-chip
              small
              :color="billingStatusColor[item.first_invoice_billed].color"
              :class="`${billingStatusColor[item.first_invoice_billed].color}--text`"
              class="v-chip-light-bg"
            >
              {{ billingStatusColor[item.first_invoice_billed].label }}
            </v-chip>
          <template v-slot:input>
            <div class="mt-4">
              {{item.job}} - Update 1st Invoice
            </div>
            <v-select
              v-model="item.first_invoice_billed"
              :items="billingStatusOptions"
              item-text="label"
              item-value="value"
              hide-details
            >
              <template #selection="data">
                <v-chip
                  v-bind="data.attrs"
                  label
                  :input-value="data.selected"
                  small
                  :color="billingStatusColor[data.item.value].color"
                  :class="`${billingStatusColor[data.item.value].color}--text`"
                  class="v-chip-light-bg"
                >
                    {{ data.item.label }}
                  </v-chip>
              </template>
              <template #item="data">
              
                <v-list-item-content>
                  <v-chip
                    small
                    :color="billingStatusColor[data.item.value].color"
                    :class="`${billingStatusColor[data.item.value].color}--text`"
                    class="v-chip-light-bg"
                  >
                    {{ data.item.label }}
                  </v-chip>
                </v-list-item-content>
              </template>
            </v-select>
          </template>
        </v-edit-dialog>
        </template>

        <template #[`item.final_invoice_billed`]="{item}">
          <span class=" font-weight-medium">{{ item.final_invoice_amount.toLocaleString('fr-FR') }}</span>
          <v-edit-dialog
            :return-value.sync="item.final_invoice_billed"
            large
            @save="updateBilling(item, 'final_invoice_billed')"
          >
            <v-chip
              small
              :color="billingStatusColor[item.final_invoice_billed].color"
              :class="`${billingStatusColor[item.final_invoice_billed].color}--text`"
              class="v-chip-light-bg"
            >
              {{ billingStatusColor[item.final_invoice_billed].label }}
            </v-chip>
            <template v-slot:input>
              <div class="mt-4">
                {{item.job}} - Update Final Invoice
              </div>
              <v-select
                v-model="item.final_invoice_billed"
                :items="billingStatusOptions"
                item-text="label"
                item-value="value"
                hide-details
              >
                <template #selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    label
                    :input-value="data.selected"
                    small
                    :color="billingStatusColor[data.item.value].color"
                    :class="`${billingStatusColor[data.item.value].color}--text`"
                    class="v-chip-light-bg"
                  >
                      {{ data.item.label }}
                    </v-chip>
                </template>
                <template #item="data">
                
                  <v-list-item-content>
                    <v-chip
                      small
                      :color="billingStatusColor[data.item.value].color"
                      :class="`${billingStatusColor[data.item.value].color}--text`"
                      class="v-chip-light-bg"
                    >
                      {{ data.item.label }}
                    </v-chip>
                  </v-list-item-content>
                </template>
              </v-select>
            </template>
          </v-edit-dialog>
        </template>

        <template #[`item.oop_invoice_billed`]="{item}">
          <span class=" font-weight-medium">{{ item.oop_invoice_amount.toLocaleString('fr-FR') }}</span>
          <v-edit-dialog
            :return-value.sync="item.oop_invoice_billed"
            large
            @save="updateBilling(item, 'oop_invoice_billed')"
          >
            <v-chip
              small
              :color="billingStatusColor[item.oop_invoice_billed].color"
              :class="`${billingStatusColor[item.oop_invoice_billed].color}--text`"
              class="v-chip-light-bg"
            >
              {{ billingStatusColor[item.oop_invoice_billed].label }}
            </v-chip>
            <template v-slot:input>
              <div class="mt-4">
                {{item.job}} - Update OOP Invoice
              </div>
              <v-select
                v-model="item.oop_invoice_billed"
                :items="billingStatusOptions"
                item-text="label"
                item-value="value"
                hide-details
              >
                <template #selection="data">
                  <v-chip
                    v-bind="data.attrs"
                    label
                    :input-value="data.selected"
                    small
                    :color="billingStatusColor[data.item.value].color"
                    :class="`${billingStatusColor[data.item.value].color}--text`"
                    class="v-chip-light-bg"
                  >
                      {{ data.item.label }}
                    </v-chip>
                </template>
                <template #item="data">
                
                  <v-list-item-content>
                    <v-chip
                      small
                      :color="billingStatusColor[data.item.value].color"
                      :class="`${billingStatusColor[data.item.value].color}--text`"
                      class="v-chip-light-bg"
                    >
                      {{ data.item.label }}
                    </v-chip>
                  </v-list-item-content>
                </template>
              </v-select>
            </template>
          </v-edit-dialog>
        </template>

        <!-- insights -->
        
        <template #[`item.insights`]="{item}">
        <section class="avatars-group pa-3 stacked">
          <div v-for="insight in item.insights" 
              :key="insight"
              class="avatars-group__item"
          >
            <v-tooltip 
              top
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on">
                  <v-avatar
                    :color="stringToHslColor(insight) || primary"
                    size="30"
                  >
                    <span class="white--text text-xs">{{avatarText(insight)}}</span>
                  </v-avatar>
                </div>
              </template>
              <v-layout column>
                <span class="font-weight-medium">{{insight}}</span>
              </v-layout>
            </v-tooltip>
          </div>
        </section>
        </template>

        
              
        <template #[`item.updated_at`]="{item}">
          <div>
              {{item.updated_at.substring(0,10)}}
          </div>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item
                :to="{name:'project-view',params:{id:item.job_number}}"
                target="_blank"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiFileDocumentOutline }}
                  </v-icon>
                  <span>Details</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item 
                :to="{name:'project-timeline',params:{id:item.job_number}}"
                target="_blank"
              >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiChartTimeline }}
                  </v-icon>
                  <span>Timeline</span>
                </v-list-item-title>
              </v-list-item>

              <v-list-item link
                :href="item.folder_web_url"
                target="_blank"
                >
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiMicrosoftSharepoint }}
                  </v-icon>
                  <span>Sharepoint</span>
                </v-list-item-title>
              </v-list-item>

            </v-list>
          </v-menu>
        </template>


        <template v-slot:expanded-item="{ headers, item }"
          v-if="view=='Accounting'"
        >
          <td :colspan="headers.length">
            <strong>Billing Comments:</strong>
            <v-edit-dialog
              :return-value.sync="item.billing_notes"
              large
              @save="updateBilling(item, 'billing_notes')"
            >
             <div>
              {{item.billing_notes}}
             </div>
              <template v-slot:input>
                <div class="mt-4">
                  {{item.job}} - Update OOP Invoice
                </div>
                <v-textarea
                  v-model="item.billing_notes"
                >
                </v-textarea>
              </template>
            </v-edit-dialog>
            
          </td>
        </template>

      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiFileDocumentOutline,
  mdiChartTimeline,
  mdiExportVariant,
  mdiAccountOutline,
  mdiMicrosoftSharepoint,
  mdiSend,
  mdiDownload,
  mdiOrderBoolDescendingVariant,
} from '@mdi/js'
import store from '@/store'
import { onUnmounted, ref, onMounted } from '@vue/composition-api'

// sidebar
import { avatarText } from '@core/utils/filter'
import { stringToHslColor, defaultProject } from '@core/utils'
import ProjectListAddNew from './ProjectListAddNew.vue'
import projectStoreModule from '../projectStoreModule'

import useProjectsList from './useProjectsList'
import useProject from '../useProject'
import useProjectView from '../project-view/useProjectView'


export default {
  components: {
    ProjectListAddNew,
  },
  setup() {
    const PROJECT_APP_STORE_MODULE_NAME = 'app-project'
    // Register module
    if (!store.hasModule(PROJECT_APP_STORE_MODULE_NAME)) store.registerModule(PROJECT_APP_STORE_MODULE_NAME, projectStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROJECT_APP_STORE_MODULE_NAME)) store.unregisterModule(PROJECT_APP_STORE_MODULE_NAME)
    })

    const expanded = ref([])

    const { updateProject, resolveCountryStatusVariant } = useProjectView()

    const updateBilling = (item, field) => {
      const projectData = {
        id: item.id,
        job_number: item.job_number
      }
      projectData[field] = item[field]
      
      updateProject(projectData)
    }


    const {
      view,
      projectListTable,
      tableColumns,
      searchQuery,
      deptFilter,
      groupFilter,
      officeFilter,
      statusFilter,
      totalProjectListTable,
      loading,
      
      options,
      projectTotalLocal,
      selectedRows,

      loadFiltersFromLS,
      submitSearch,
      
      resolveProjectStatusVariant,
      resolveProjectTotalIcon,
      updateFilter,
      downloadProjects
    } = useProjectsList()

    
    const {
      billingStatusOptions,
      billingStatusColor,
      deptLoading,
      groupLoading,
      officeLoading,
      deptOptions,
      groupOptions,
      officeOptions,
      deptSearch,
      groupSearch,
      officeSearch,
      statusLoading,
      statusOptions,
      statusSearch,

      fetchDepartments,
      fetchGroups,
      fetchOffices,
      fetchStatuses
      
    } = useProject()

    const clickDownloadProjects = () => {
      downloadProjects()
    }


    onMounted(() => {

      loadFiltersFromLS(),
      fetchDepartments()
      fetchGroups()
      fetchOffices()
      fetchStatuses()
    })



    return {
      view,
      billingStatusOptions,
      billingStatusColor,
      updateBilling,
      expanded,
      projectListTable,
      tableColumns,
      searchQuery,
      deptFilter,
      groupFilter,
      officeFilter,
      totalProjectListTable,
      deptOptions,
      groupOptions,
      officeOptions,
      loading,
      deptLoading,
      groupLoading,
      officeLoading,
      statusFilter,
      statusLoading,
      statusOptions,
      statusSearch,
      options,
      projectTotalLocal,
      selectedRows,
      deptSearch,
      groupSearch,
      officeSearch,
      
      submitSearch,
      avatarText,
      stringToHslColor,
      resolveProjectStatusVariant,
      resolveProjectTotalIcon,
      updateFilter,
      clickDownloadProjects,
      resolveCountryStatusVariant,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiFileDocumentOutline,
        mdiDotsVertical,
        mdiChartTimeline,
        mdiPlus,
        mdiExportVariant,
        mdiAccountOutline,
        mdiMicrosoftSharepoint,
        mdiSend,
        mdiOrderBoolDescendingVariant,
        mdiDownload
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/project.scss';
</style>
