import store from '@/store'

import { ref, getCurrentInstance } from '@vue/composition-api'
import { foundryRequest } from '@/config/authConfig';


export default function useProjectAddNew() {
    // Stepper's active step
    const activeStep = ref(1)
    const vm = getCurrentInstance().proxy

    // Form Submitted Snackbar
    const isFormSubmittedSnackbarVisible = ref(false)
    

    const addProject = async (projectData) => {
        const token = await vm.$msal.getTokenPopup(foundryRequest)
        const projectDataLocal = JSON.parse(JSON.stringify(projectData.value))

        store
            .dispatch('app-project/addProject', projectDataLocal)
            .then((response) => {
                const { data } = response
                
                store.commit('comp-alert/SET_MESSAGE', {})
            })
            .catch(error => {
                store.commit('comp-alert/SET_MESSAGE', error.response.data)
            })
    }
    


    return {
        activeStep,
        isFormSubmittedSnackbarVisible,
       
        addProject,
    }

}