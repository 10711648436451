<template>
  <v-navigation-drawer
    :value="isAddNewProjectSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    width="100%"
    app
    @input="(val) => {$emit('update:is-add-new-project-sidebar-active', val);}"
  >
    <alert />
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Create New Project</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-new-project-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-stepper
        v-model="activeStep"
        class="custom-header"
      >
        <!-- Header -->
        <v-stepper-header>
          <!-- Header: Step 1 -->
          <v-stepper-step
            :complete="activeStep > 1"
            step="1"
            :rules="[() => stepValidationResult[1]]"
          >
            <div class="d-flex align-center">
              <span
                class="text-4xl font-weight-bold me-3"
                :class="stepValidationResult[1] ? 'text--primary' : 'error--text'"
              >1</span>
              <div class="d-flex flex-column">
                <span
                  class="text-sm font-weight-semibold"
                  :class="stepValidationResult[1] ? 'text--primary' : 'error--text'"
                >Project Details</span>
                <span
                  class="text-xs"
                  :class="stepValidationResult[1] ? 'text--secondary' : 'error--text'"
                >Overall information for project</span>
              </div>
            </div>
          </v-stepper-step>

          <v-divider></v-divider>
          <template
            v-for="(country, index) in projectData.countries" >
            <v-stepper-step 
              :key="country.value"
              :complete="activeStep > (2+index)"
              :step="2+index"
            >
              <div class="d-flex align-center">
                <span
                  class="text-4xl font-weight-bold me-3"
                  :class="stepValidationResult[2+index] ? 'text--primary' : 'error--text'"
                >{{2+index}}</span>
                <div class="d-flex flex-column">
                  <span
                    class="text-sm font-weight-semibold"
                    :class="stepValidationResult[2+index] ? 'text--primary' : 'error--text'"
                  >Field Country</span>
                  <span
                    class="text-xs"
                    :class="stepValidationResult[2+index] ? 'text--secondary' : 'error--text'"
                  >{{country.label}}</span>
                </div>
              </div>
            </v-stepper-step>
            <v-divider :key="'divider'+country.value"></v-divider>
          </template>
          

          <!-- Header: Step 2 -->
          <v-stepper-step
            :complete="activeStep > (2+(!!projectData.countries ? projectData.countries.length : 0))"
            :step="2+(!!projectData.countries ? projectData.countries.length : 0)"
            :rules="[() => stepValidationResult[2+(!!projectData.countries ? projectData.countries.length : 0)]]"
          >
            <div class="d-flex align-center">
              <span
                class="text-4xl font-weight-bold me-3"
                :class="stepValidationResult[2+(!!projectData.countries ? projectData.countries.length : 0)] ? 'text--primary' : 'error--text'"
              >{{2+(!!projectData.countries ? projectData.countries.length : 0)}}</span>
              <div class="d-flex flex-column">
                <span
                  class="text-sm font-weight-semibold"
                  :class="stepValidationResult[2+(!!projectData.countries ? projectData.countries.length : 0)] ? 'text--primary' : 'error--text'"
                >Give me the money</span>
                <span
                  class="text-xs"
                  :class="stepValidationResult[2+(!!projectData.countries ? projectData.countries.length : 0)] ? 'text--secondary' : 'error--text'"
                >Financial Information</span>
              </div>
            </div>
          </v-stepper-step>

          <v-divider></v-divider>

          <!-- Header: Step 3 -->
          <v-stepper-step
            :step="3+(!!projectData.countries ? projectData.countries.length : 0)"
            :rules="[() => stepValidationResult[3+(!!projectData.countries ? projectData.countries.length : 0)]]"
          >
            <div class="d-flex align-center">
              <span
                class="text-4xl font-weight-bold me-3"
                :class="stepValidationResult[3+(!!projectData.countries ? projectData.countries.length : 0)] ? 'text--primary' : 'error--text'"
              >{{3+(!!projectData.countries ? projectData.countries.length : 0)}}</span>
              <div class="d-flex flex-column">
                <span
                  class="text-sm font-weight-semibold"
                  :class="stepValidationResult[3+(!!projectData.countries ? projectData.countries.length : 0)] ? 'text--primary' : 'error--text'"
                >Review &nbsp; Submit</span>
                <span
                  class="text-xs"
                  :class="stepValidationResult[3+(!!projectData.countries ? projectData.countries.length : 0)] ? 'text--secondary' : 'error--text'"
                >Project Submission</span>
              </div>
            </div>
          </v-stepper-step>
        </v-stepper-header>

        <!-- Stepper Items -->
        <v-stepper-items>
          <!-- Stepper Content: Step 1 -->
          <v-stepper-content step="1">
            <v-form
              :ref="`step1Form`"
              class="pb-6 pt-2"
            >
              <v-row>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-text-field
                    v-model="projectData.job_name"
                    label="Job Name"
                    hint="Enter the name of your project"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Job Name"
                    counter
                    maxlength="42"
                    persistent-hint
                    :rules="[validators.required, validators.alphaDashValidator]"
                  ></v-text-field>
                </v-col>

                <v-col
                  cols="12"
                  md="3"
                >
                  <v-autocomplete
                    v-model="projectData.office"
                    :loading="officeLoading"
                    :items="officeOptions"
                    :search-input.sync="officeSearch"
                    item-text="label"
                    item-value="value"
                    label="Office in Charge"
                    hint="Where are you based?"
                    cache-items
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Office"
                    @change="officeSearch=''"
                    persistent-hint
                    :rules="[validators.required, validators.integerValidator]"
                  ></v-autocomplete>
                </v-col>
                <v-col 
                  cols="12"
                  md="3"
                >
                  <v-autocomplete
                    v-model="projectData.contact"
                    :loading="userLoading"
                    :items="filterInsights"
                    :search-input.sync="userSearch"
                    item-text="name"
                    item-value="azure_ad_id"
                    label="PRSIV Contact in Charge"
                    hint="PRSIV Contact"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Contact"
                    @change="userSearch=''"
                    persistent-hint
                    :rules="[validators.required]"
                    :menu-props="{ offsetY: true, contentClass: 'list-style' }"
                    >
                      <!-- Options Slot -->
                      <template #item="{ item }">
                        <div class="d-flex align-center">
                          <v-avatar
                                :color="stringToHslColor(item.name) || primary"
                                size="25"
                                class="me-2"
                            >
                                <span :class="`white--text text-xs`">{{avatarText(item.name)}}</span>
                            </v-avatar>
                          <span class="text-sm">{{ item.name }}</span>
                        </div>
                      </template>
                    </v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-menu
                    :close-on-content-click="true"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="projectData.closed_won_date"
                        label="Close Won Date"
                        :prepend-icon="icons.mdiCalendar"
                        outlined
                        dense
                        hide-details="auto"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hint="Same date as your Hubspot deal"
                        disabled
                        persistent-hint
                        :rules="[validators.required, validators.startDateValidator]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="projectData.closed_won_date"
                      no-title
                      :first-day-of-week="1"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-menu
                    :close-on-content-click="true"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="projectData.report_date"
                        label="Report Date"
                        :prepend-icon="icons.mdiCalendar"
                        outlined
                        dense
                        hide-details="auto"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hint="Estimated report date based on proposal"
                        persistent-hint
                        :rules="[validators.required, validators.reportDateValidator]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="projectData.report_date"
                      no-title
                      :first-day-of-week="1"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col 
                  cols="12"
                  md="4"
                >
                  <v-autocomplete
                    v-model="projectData.client"
                    :loading="clientLoading"
                    :items="clientOptions"
                    :search-input.sync="clientSearch"
                    item-text="label"
                    item-value="value"
                    label="Client"
                    hint="Enter the client name (same as Hubspot)"
                    persistent-hint
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Client"
                    @change="clientSearch=''"
                    :rules="[validators.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    class="uppercase"
                    v-model="projectData.brand"
                    label="Brand"
                    hint="Enter the brand related to your project"
                    persistent-hint
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Brand"
                    :rules="[validators.required]"
                  ></v-text-field>
                </v-col>
                <v-col 
                  cols="12"
                  md="5"
                >
                  <v-autocomplete
                    v-model="projectData.category"
                    :loading="categoryLoading"
                    :items="categoryOptions"
                    :search-input.sync="categorySearch"
                    item-text="label"
                    item-value="value"
                    label="Category"
                    hint="Choose the relevant category for your project"
                    persistent-hint
                    cache-items
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Category"
                    @change="categorySearch=''"
                    :rules="[validators.required]"
                  ></v-autocomplete>
                
                </v-col>
                
                <v-col 
                  cols="12"
                  md="3">
                  <v-autocomplete
                    v-model="projectData.project_type"
                    :loading="projTypeLoading"
                    :items="projTypeOptions"
                    :search-input.sync="projTypeSearch"
                    item-text="label"
                    item-value="value"
                    label="Project Type"
                    hint="Choose your project type"
                    persistent-hint
                    cache-items
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Project Type"
                    @change="projTypeSearch=''"
                    :rules="[validators.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col 
                  cols="12"
                  md="3">
                  <v-autocomplete
                    v-model="projectData.research_method"
                    :loading="researchMetLoading"
                    :items="researchMetOptions"
                    :search-input.sync="researchMetSearch"
                    item-text="label"
                    item-value="value"
                    label="Research Method"
                    hint="is it going to be online? in-person? maybe? I don't know?"
                    persistent-hint
                    cache-items
                    outlined
                    dense
                    multiple
                    small-chips
                    deletable-chips
                    hide-details="auto"
                    placeholder="Research Method"
                    @change="researchMetSearch=''"
                    :rules="[validators.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col 
                  cols="12"
                  md="6">
                  <v-autocomplete
                    v-model="projectData.business_initiative"
                    :loading="busInitLoading"
                    :items="busInitOptions"
                    :search-input.sync="busInitSearch"
                    item-text="label"
                    item-value="value"
                    label="Business Initiative"
                    hint="Choose your business initiative"
                    persistent-hint
                    cache-items
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Business Initiative"
                    @change="busInitSearch=''"
                    :rules="[validators.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col 
                  cols="12"
                  md="6">
                  <v-autocomplete
                    v-model="projectData.service_type"
                    :loading="servTypeLoading"
                    :items="servTypeOptions"
                    :search-input.sync="servTypeSearch"
                    item-text="label"
                    item-value="value"
                    label="Service Types"
                    hint="But...What have you sold? Choose wisely!"
                    persistent-hint
                    cache-items
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Service Types"
                    @change="servTypeSearch=''"
                    :rules="[validators.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col 
                  cols="12"
                  md="6"
                >
                  <v-autocomplete
                    v-model="projectData.countries"
                    :loading="countryLoading"
                    :items="countryOptions"
                    :search-input.sync="countrySearch"
                    item-text="label"
                    item-value="value"
                    label="Field Country"
                    hint="Choose the country(ies) where the project is going to be fielded"
                    persistent-hint
                    cache-items
                    outlined
                    dense
                    multiple
                    small-chips
                    deletable-chips
                    hide-details="auto"
                    placeholder="Field Country"
                    @change="countrySearch=''"
                    :rules="[validators.requiredArray]"
                    return-object
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>

            <div class="d-flex justify-end">
              <v-btn
                color="primary"
                class="me-2"
                @click="handleStepValidation(1)"
              >
                Next
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                type="reset"
                @click="resetprojectData"
              >
                Cancel
              </v-btn>
            </div>
          </v-stepper-content>

          <v-stepper-content 
            v-for="(country, index) in projectData.countries" 
            :key="country.value"
            :step="2+index">
            <v-form
              :ref="`step${2+index}Form`"
              class="pb-6 pt-2"
            >
              <v-row>
                <v-col 
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    v-model="country.label"
                    label="Country"
                    outlined
                    required
                    dense
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col 
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    v-model="country.sample"
                    label="Sample Size"
                    type="number"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Sample Size"
                    :rules="[validators.required, validators.integerValidator]"
                  ></v-text-field>
                </v-col>
                <v-col 
                  cols="12"
                  md="2">
                  <v-text-field
                    v-model="country.phase"
                    label="Cells"
                    type="number"
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Cells"
                    hint="Number of cell(s)"
                    persistent-hint
                    :rules="[validators.required, validators.integerValidator]"
                  ></v-text-field>
                </v-col>
                <v-col 
                  cols="12"
                  md="4">
                  <v-autocomplete
                    v-model="country.research_location"
                    :loading="researchLocLoading"
                    :items="researchLocOptions"
                    item-text="label"
                    item-value="value"
                    label="Research Location"
                    hint="ShopperLab = Multi-category env. CLT = Single-category env. In-Store = Real Store"
                    persistent-hint
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Research Location"
                    :rules="[validators.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-menu
                    :close-on-content-click="true"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="country.field_start_date"
                        label="Field Start"
                        :prepend-icon="icons.mdiCalendar"
                        outlined
                        dense
                        hide-details="auto"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hint="What's your best guess?"
                        persistent-hint
                        :rules="[validators.required, validators.fieldStartDateValidator(country)]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="country.field_start_date"
                      no-title
                      :first-day-of-week="1"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-menu
                    :close-on-content-click="true"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="country.field_end_date"
                        label="Field End"
                        :prepend-icon="icons.mdiCalendar"
                        outlined
                        dense
                        hide-details="auto"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hint="When will it end?"
                        persistent-hint
                        :rules="[validators.required, validators.fieldEndDateValidator(country)]"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="country.field_end_date"
                      no-title
                      :first-day-of-week="1"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col 
                  cols="12"
                  md="3">
                  <v-autocomplete
                    v-model="country.shelf_type"
                    :loading="shelfTypeLoading"
                    :items="shelfTypeOptions"
                    item-text="label"
                    item-value="value"
                    label="Shelf Type"
                    hint="2D Shelf = online or need of posters. 3D Shelf or No Shelf = Studio can relax!"
                    persistent-hint
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Shelf Type"
                    :rules="[validators.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col 
                  cols="12"
                  md="3">
                  <v-autocomplete
                    v-model="country.studio_rate_card"
                    :loading="studioRateCardLoading"
                    :items="studioRateCardOptions"
                    item-text="label"
                    item-value="value"
                    label="Studio Rate Card"
                    hint="Option 1 = Cheap,..., Option 6 = Expensive"
                    persistent-hint
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Studio Rate Card"
                    :rules="[validators.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="6">
                  <v-autocomplete
                    v-model="country.modules"
                    :loading="moduleLoading"
                    :items="moduleOptions"
                    item-text="label"
                    item-value="value"
                    label="Modules"
                    hint="Modules?! Make your selection(s)"
                    persistent-hint
                    outlined
                    dense
                    multiple
                    small-chips
                    deletable-chips
                    hide-details="auto"
                    placeholder="Modules"
                  ></v-autocomplete>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  v-if="projectData.project_type == 2">
                  <v-autocomplete
                    v-model="country.qual_sample_type"
                    :loading="qualSampleLoading"
                    :items="qualSampleOptions"
                    item-text="label"
                    item-value="value"
                    label="Qual Sample Type"
                    hint="Select your Qualitative Sample Type(s)"
                    persistent-hint
                    outlined
                    dense
                    multiple
                    small-chips
                    deletable-chips
                    hide-details="auto"
                    placeholder="Qual Sample Type"
                    :rules="[validators.required]"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>

            <div class="d-flex justify-space-between">
              <v-btn
                outlined
                @click="handleStepValidation(2+index, false)"
              >
                Previous
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                class="me-2"
                @click="handleStepValidation(2+index)"
              >
                Next
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                type="reset"
                @click="resetprojectData"
              >
                Cancel
              </v-btn>
            </div>
          </v-stepper-content>


          <!-- Stepper Content: Step 2 -->
          <v-stepper-content 
            :step="2+(!!projectData.countries ? projectData.countries.length : 0)"
          >
            <v-form
              :ref="`step${2+(!!projectData.countries ? projectData.countries.length : 0)}Form`"
              class="pb-6 pt-2 multi-col-validation"
            >
              <v-row>
                <v-col 
                cols="12"
                  md="2">
                  <v-autocomplete
                    v-model="projectData.currency"
                    :loading="currencyLoading"
                    :items="currencyOptions"
                    :search-input.sync="currencySearch"
                    item-text="label"
                    item-value="value"
                    label="Currency"
                    hint="Hubspot deal currency"
                    persistent-hint
                    cache-items
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Currency"
                    @change="currencySearch=''"
                    :rules="[validators.required]"
                  ></v-autocomplete>
                </v-col>

                <v-col 
                  cols="12"
                  md="6">
                  <v-text-field
                    v-model="projectData.budget"
                    label="Budget"
                    type="number"
                    hint="Please enter the project amount (OOP included)"
                    persistent-hint
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Budget"
                    :rules="[validators.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12"
                  sm="4"
                  md="4">
                  <v-text-field
                    v-model="projectData.estimated_gross_margin"
                    label="Gross Margin"
                    type="number"
                    hint="Estimation of your gross margin"
                    persistent-hint
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Gross Margin"
                    :rules="[validators.required]"
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="12"
                  sm="4"
                  md="4">
                  <v-text-field
                    v-model="projectData.oop"
                    label="OOP"
                    type="number"
                    hint="Estimation of product purchase cost (if any)"
                    persistent-hint
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Budget"
                    :rules="[validators.required]"
                  ></v-text-field>
                </v-col> -->
                <v-col 
                  cols="12"
                  md="4">
                  <v-autocomplete
                    v-model="projectData.billing_rule"
                    :loading="billingRuleLoading"
                    :items="billingRuleOptions"
                    :search-input.sync="billingRuleSearch"
                    item-text="label"
                    item-value="value"
                    label="Billing Rules"
                    hint="Most of the time it's 50 - 50. 1st figure is at field start and last one is at report date"
                    persistent-hint
                    cache-items
                    outlined
                    dense
                    hide-details="auto"
                    placeholder="Billing Rules"
                    @change="billingRuleSearch=''"
                    :rules="[validators.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col 
                  cols="12"
                  md="4">
                  <v-text-field
                    v-model="projectData.po"
                    label="PO"
                    hint="If you have it, please enter it."
                    persistent-hint
                    outlined
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>

                <v-col v-if="projectData.office == 1"
                  cols="12"
                  sm="4"
                  md="4">
                  <v-text-field
                    v-model="projectData.ges_bva"
                    label="Ges BVA Number"
                    outlined
                    dense
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>

            <div class="d-flex justify-space-between">
              <v-btn
                outlined
                @click="handleStepValidation(2+(!!projectData.countries ? projectData.countries.length : 0), false)"
              >
                Previous
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                class="me-2"
                @click="handleStepValidation(2+(!!projectData.countries ? projectData.countries.length : 0))"
              >
                Next
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                type="reset"
                @click="resetprojectData"
              >
                Cancel
              </v-btn>
            </div>
          </v-stepper-content>

          <!-- Stepper Content: Step 3 -->
          <v-stepper-content 
            :step="3+(!!projectData.countries ? projectData.countries.length : 0)"
            >
            <v-form
              :ref="`step${3+(!!projectData.countries ? projectData.countries.length : 0)}Form`"
              class="pb-6 pt-2 multi-col-validation"
            >
              <v-row>
                <v-col 
                  cols="12"
                  md="6">
                  <v-autocomplete
                    v-model="projectData.insights"
                    :loading="userLoading"
                    :items="filterInsights"
                    :search-input.sync="insightSearch"
                    item-text="name"
                    item-value="azure_ad_id"
                    label="Insights Team Member"
                    hint="Choose wisely."
                    persistent-hint
                    cache-items
                    outlined
                    dense
                    multiple
                    small-chips
                    deletable-chips
                    placeholder="Insights Team Member"
                    @change="insightSearch=''"
                    :rules="[validators.required]"
                    :menu-props="{ offsetY: true, contentClass: 'list-style' }"
                    >
                      <!-- Options Slot -->
                      <template #item="{ item }">
                        <div class="d-flex align-center">
                          <v-avatar
                                :color="stringToHslColor(item.name) || primary"
                                size="25"
                                class="me-2"
                            >
                                <span :class="`white--text text-xs`">{{avatarText(item.name)}}</span>
                            </v-avatar>
                          <span class="text-sm">{{ item.name }}</span>
                        </div>
                      </template>
                    </v-autocomplete>
                </v-col>
                <v-col 
                  cols="12"
                  md="6">
                  <v-autocomplete
                    v-model="projectData.departments"
                    :loading="deptLoading"
                    :items="deptOptions"
                    :search-input.sync="deptSearch"
                    item-text="label"
                    item-value="value"
                    label="Departments"
                    hint="Select Departments"
                    persistent-hint
                    cache-items
                    outlined
                    dense
                    multiple
                    small-chips
                    deletable-chips
                    @change="deptSearch=''"
                    placeholder="Departments"
                    :rules="[validators.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col 
                  cols="12"
                  md="6">
                  <v-autocomplete
                    v-model="projectData.group_id"
                    :loading="groupLoading"
                    :items="groupOptions"
                    :search-input.sync="groupSearch"
                    item-text="name"
                    item-value="group_id"
                    label="Teams Group"
                    hint="Select where the project Teams channel needs to be created."
                    persistent-hint
                    cache-items
                    outlined
                    dense
                    placeholder="Teams Group"
                    @change="groupSearch=''"
                    :rules="[validators.required]"
                  ></v-autocomplete>
                </v-col>
                <v-col 
                cols="12"
                md="12">
                  <v-textarea
                    v-model="projectData.key_comments"
                    label="Comments"
                    outlined
                    hide-details
                    placeholder="Comments"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-form>

            <!-- Action Buttons -->
            <div class="d-flex justify-space-between">
              <v-btn
                outlined
                @click="handleStepValidation(3+(!!projectData.countries ? projectData.countries.length : 0), false)"
              >
                Previous
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                class="me-2"
                @click="handleStepValidation(3+(!!projectData.countries ? projectData.countries.length : 0), true, true)"
              >
                Complete
              </v-btn>
              <v-btn
                color="secondary"
                outlined
                type="reset"
                @click="resetprojectData"
              >
                Cancel
              </v-btn>
            </div>

            <v-snackbar v-model="isFormSubmittedSnackbarVisible">
              Form is being submitted.
            </v-snackbar>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose, mdiCalendar } from '@mdi/js'
import { dateInPast } from '@core/utils'
import { ref, computed, getCurrentInstance, onMounted, onUnmounted, watch } from '@vue/composition-api'
import store from '@/store'
import projectStoreModule from '../projectStoreModule'
import { foundryRequest } from '@/config/authConfig';
import {
  required,
  requiredArray,
  alphaDashValidator,
  integerValidator,
  billingRuleValidator,
} from '@core/utils/validation'
import useProjectAddNew from './useProjectAddNew'
import useProject from '../useProject'
import Alert from '@/views/components/alert/Alert.vue'
import { stringToHslColor } from '@core/utils'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    Alert
  },
  model: {
    prop: 'isAddNewProjectSidebarActive',
    event: 'update:is-add-new-project-sidebar-active',
  },
  props: {
    isAddNewProjectSidebarActive: {
      type: Boolean,
      required: true
    },
    blankProjectData: {
      type: Object,
      required: false
    }
  },
  setup(props, { emit }) {

    
    const PROJECT_APP_STORE_MODULE_NAME = 'app-project'
    // Register module
    if (!store.hasModule(PROJECT_APP_STORE_MODULE_NAME)) store.registerModule(PROJECT_APP_STORE_MODULE_NAME, projectStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROJECT_APP_STORE_MODULE_NAME)) store.unregisterModule(PROJECT_APP_STORE_MODULE_NAME)
    })


    const vm = getCurrentInstance().proxy
    const projectData = ref(JSON.parse(JSON.stringify(props.blankProjectData)))

    const { 
      officeLoading,
      officeOptions,
      officeSearch,
      categoryLoading,
      categoryOptions,
      categorySearch,
      busInitLoading,
      busInitOptions,
      busInitSearch,
      countryLoading,
      countryOptions,
      countrySearch,
      moduleLoading,
      moduleOptions,
      moduleSearch,
      projTypeLoading,
      projTypeOptions,
      projTypeSearch,
      researchLocLoading,
      researchLocOptions,
      researchLocSearch,
      researchMetLoading,
      researchMetOptions,
      researchMetSearch,
      servTypeLoading,
      servTypeOptions,
      servTypeSearch,
      billingRuleLoading,
      billingRuleOptions,
      billingRuleSearch,
      currencyLoading,
      currencyOptions,
      currencySearch,
      clientLoading,
      clientOptions,
      clientSearch,
      userLoading,
      userOptions,
      userSearch,
      shelfTypeLoading,
      shelfTypeOptions,
      shelfTypeSearch,
      studioRateCardLoading,
      studioRateCardOptions,
      studioRateCardSearch,
      qualSampleLoading,
      qualSampleOptions,
      qualSampleSearch,
      deptLoading,
      deptOptions,
      deptSearch,
      groupLoading,
      groupOptions,
      groupSearch,

      fetchOffices,
      fetchCategories,
      fetchBusinessInit,
      fetchCountries,
      fetchModules,
      fetchProjectTypes,
      fetchResearchLocations,
      fetchResearchMethods,
      fetchServiceTypes,
      fetchBillingRules,
      fetchCurrencies,
      fetchUsers,
      fetchClients,
      fetchShelfTypes,
      fetchStudioRateCards,
      fetchQualSampleType,
      fetchGroups,
      fetchDepartments,

    } = useProject()

    const insightSearch = ref('')

    const {
      activeStep,
      isFormSubmittedSnackbarVisible,
      addProject,

    } = useProjectAddNew()
    

    const filterInsights = computed(() => {
      return userOptions.value.filter(item => item.department === 5)
    })

    const initProjectAttributesLoad = () => {
        fetchOffices()
        fetchCategories()
        fetchBusinessInit()
        fetchCountries()
        fetchModules()
        fetchProjectTypes()
        fetchResearchLocations()
        fetchResearchMethods()
        fetchServiceTypes()
        fetchBillingRules()
        fetchCurrencies()
        fetchUsers()
        fetchClients()
        fetchShelfTypes()
        fetchStudioRateCards()
        fetchQualSampleType()
        fetchGroups()
        fetchDepartments()
    }
    

    // Step Validation (for `rules` prop of `v-stepper-step`)
    // It will be updated when step is validated
    const stepValidationResult = ref({
        1: true,
        2: true,
        3: true,
        4: true,
        5: true,
        6: true,
        7: true,
        8: true,
        9: true,
        10: true,
        11: true,
        12: true,
        13: true,
        14: true,
        15: true,
    })
    
    const resetprojectData = () => {
      projectData.value = JSON.parse(JSON.stringify(props.blankProjectData))
      // resetForm()
      activeStep.value = 1
      emit('update:is-add-new-project-sidebar-active', false)
    }

    const fieldStartDateValidator = (country) => {
 
      if (dateInPast(new Date(country.field_start_date), new Date(projectData.value.closed_won_date))) return 'Field start date cannot be set before deal won date'
      if (dateInPast(new Date(projectData.value.report_date), new Date(country.field_start_date))) return 'Field start date cannot be set after the report date'
      if (country.field_start_date && dateInPast(new Date(country.field_end_date), new Date(country.field_start_date))) return 'Field start date cannot be set after the field end date'

      return true
    }
    
    const fieldEndDateValidator = country => {
      if (dateInPast(new Date(country.field_end_date), new Date(projectData.value.closed_won_date))) return 'Field end date cannot be set before deal won date'
      if (dateInPast(new Date(projectData.value.report_date), new Date(country.field_end_date))) return 'Field end date cannot be set after the report date'
      if (country.field_end_date && dateInPast(new Date(country.field_end_date), new Date(country.field_start_date))) return 'Field end date cannot be set before the field start date'

      return true
    }

    const reportDateValidator = val => {
      if (!projectData.value.closed_won_date) return true
      
      return !dateInPast(new Date(val), new Date(projectData.value.closed_won_date)) || 'Report date is in past'
    }

    const startDateValidator = val => {
      if (!projectData.value.report_date) return true
      
      return !dateInPast(new Date(projectData.value.report_date), new Date(val)) || 'Start date is in before report date'
    }


    // Step Validation
    const handleStepValidation = async (step, navigateNext = true, isFinalStep = false) => {
      let formRef = `step${step}Form`
      // Get Form templateRef of Step from argument
      const templateRef = (() => {
        //Check Ref is array format or not. When dynamically adding ref in v-for loop it creates an array instead of instance
        if (step) return Array.isArray(vm.$refs[formRef]) ? vm.$refs[formRef][0] : vm.$refs[formRef]

        return null
      })()

      // If no validate step templateRef is found just return
      if (!templateRef) return
      if (!navigateNext) {
        activeStep.value -= 1
        return
      }

      // If step is valid proceed to the next step
      const isStepValid = templateRef.validate()

      // Assign to stepValidationResult
      stepValidationResult.value[step] = isStepValid
      // const isStepValid = true
      // console.log(projectData.value)
      if (isStepValid) {
        if (navigateNext && !isFinalStep) activeStep.value += 1
        

        // Show Snackbar
        if (isFinalStep) {
          // ? Some Ajax request which saves form data in database

          const token = await vm.$msal.getTokenPopup(foundryRequest)
          const projectDataLocal = JSON.parse(JSON.stringify(projectData.value))
          projectDataLocal.brand = projectDataLocal.brand.toUpperCase()
          store
              .dispatch('app-project/addProject', projectDataLocal)
              .then((response) => {
                  const { data } = response
                  emit('refetch-data')
                  emit('update:is-add-new-project-sidebar-active', false)
                  
                  store.commit('comp-alert/SET_MESSAGE', {})
              })
              .catch(error => {
                  store.commit('comp-alert/SET_MESSAGE', error.response.data)
              })
          isFormSubmittedSnackbarVisible.value = true
        }
      }
    }

    watch(
      () => props.blankProjectData,
      (newVal, oldVal) => {
        console.log(newVal)
        if (newVal) {
          initProjectAttributesLoad()
          projectData.value = JSON.parse(JSON.stringify(props.blankProjectData))
        }
      }
    )

    onMounted(() => {
        
    })

    return {
      activeStep,
      projectData,
      resetprojectData,
      officeLoading,
      officeOptions,
      officeSearch,
      categoryLoading,
      categoryOptions,
      categorySearch,
      busInitLoading,
      busInitOptions,
      busInitSearch,
      countryLoading,
      countryOptions,
      countrySearch,
      moduleLoading,
      moduleOptions,
      moduleSearch,
      projTypeLoading,
      projTypeOptions,
      projTypeSearch,
      researchLocLoading,
      researchLocOptions,
      researchLocSearch,
      researchMetLoading,
      researchMetOptions,
      researchMetSearch,
      servTypeLoading,
      servTypeOptions,
      servTypeSearch,
      billingRuleLoading,
      billingRuleOptions,
      billingRuleSearch,
      currencyLoading,
      currencyOptions,
      currencySearch,
      clientLoading,
      clientOptions,
      clientSearch,
      userLoading,
      insightSearch,
      filterInsights,
      userSearch,
      shelfTypeLoading,
      shelfTypeOptions,
      shelfTypeSearch,
      studioRateCardLoading,
      studioRateCardOptions,
      studioRateCardSearch,
      qualSampleLoading,
      qualSampleOptions,
      qualSampleSearch,
      deptLoading,
      deptOptions,
      deptSearch,
      groupSearch,
      groupOptions,
      groupLoading,


      // Field Validators
      validators: {
        required,
        requiredArray,
        alphaDashValidator,
        integerValidator,
        billingRuleValidator,
        fieldStartDateValidator,
        fieldEndDateValidator,
        reportDateValidator,
        startDateValidator
      },

      // Step Validations
      stepValidationResult,
      handleStepValidation,


      // Snackbar
      isFormSubmittedSnackbarVisible,
      icons: {
        mdiClose,
        mdiCalendar,
      },
      stringToHslColor,
      avatarText,
    }
  },
}
</script>
